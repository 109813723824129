import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import skySvg from '../assets/images/bg_sky.png';
import clouds from '../assets/images/clouds.svg';
import greenaryPng from '../assets/images/bg_ground.png';
import infoIcon from '../assets/images/info-icon.svg';
import diceSvg from '../assets/images/dice.svg';
import furu from '../assets/images/furu_bg.png';
import playersInfoDiary from '../assets/images/players-info-diary.svg';
import location from '../assets/images/white_location.svg';
import DiceRoll from '../components/DiceRoll';
import { LoadingSpinnerOverlay } from '../Spinner';
import PkScreen from '../components/PkScreen';
import PlayerList from '../components/PlayerList';
import WhiteArrow from '../assets/images/right_arrow_white.png';
import Football from '../assets/images/football.svg';
import { getSugorokuMaster } from '../api/getSugorokuMaster';
import SugorokuMasterManager from '../lib/sugorokuMasterManager';
import { getAccountSugorokuProgress } from '../api/getAccountSugorokuProgress';
import { waitAsyncFunctionExecuted } from '../lib/asyncUtils';
import { postUseDice } from '../api/postUseDice';
import { SugorokuErrorDialog } from '../components/SugorokuErrorDialog';
import UseDiceResult from '../types/useDiceResult';
import Dialog from '../components/Dialog';
import { useDialog } from '../components/UseDialog';
import doleWakuwaku from '../assets/images/dole_wakuwaku.svg';
import SugorokuHelp from '../components/SugorokuHelp';
import { handleAxiosError } from '../lib/handleErrorResponse';
import useLogout from '../hooks/useLogout';
import { useShowItemNoticeBadgeContext } from '../hooks/useShowNoticeBadgeContext';
import {
    SugorokuMaster,
    SugorokuStageEvent,
    SugorokuItem,
} from '../types/sugorokuMasterType';
import SugorokuItemManager from '../lib/sugorokuItemManager';
import FullTilePath from '../components/FullTilePath';
import SessionStorageManager from '../lib/sessionStorageManager';
import { SESSION_STORAGE_KEYS } from '../constants/sessionStorageKeys';
import { ga4PushEvent } from '../ga4';
import { GA4_CUSTOM_EVENT } from '../constants/ga4CustomEvent';

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
    min-height: calc(100dvh - 48px - 82px);
    margin: 0 auto;
    overflow: hidden;

    @media (height <= 648px) {
        min-height: 648px;
    }
`;

const SkyBackground = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 45%;
    overflow: hidden;
    background-image: url(${skySvg});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
`;

const moveCloudAnimation = keyframes`
    0% {
        transform: translateX(350px);
    }
    100% {
        transform: translateX(-120%);
    }
`;

const secondMoveCloudAnimation = keyframes`
    0% {
        transform: translateX(400px);
    }
    100% {
        transform: translateX(-180%);
    }
`;

const CloudsOverlay = styled.div`
    position: absolute;
    top: 50px;
    left: 50px;
    width: 35%;
    height: 14%;
    overflow: hidden;
    background-image: url(${clouds});
    background-repeat: no-repeat;
    background-size: contain;
    animation: ${moveCloudAnimation} 140s linear infinite;
`;

const SecondCloudOverlay = styled.div`
    position: absolute;
    top: 135px;
    left: 150px;
    width: 46%;
    height: 34%;
    overflow: hidden;
    background-image: url(${clouds});
    background-repeat: no-repeat;
    background-size: contain;
    animation: ${secondMoveCloudAnimation} 90s linear infinite;
`;

const GreenaryBackground = styled.div`
    position: absolute;
    bottom: -10%;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 65%;
    background-image: url(${greenaryPng});
    background-position: center;
    background-size: cover;
`;

const MainContentContainer = styled.div`
    position: relative;
    z-index: 4;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: start;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
`;

const CircularRectangleBoxContainer = styled.div`
    position: relative;
    z-index: 4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 290px;
    height: 62px;
    background-color: white;
    border-radius: 0 0 49px;
    box-shadow: 0 3px 3px 0 rgb(0 0 0 / 16%);
`;

const ConftTitleContainer = styled.div`
    position: absolute;
    top: 45%;
    left: 53%;
    z-index: 3;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 75vw;
    font-weight: 900;
    color: black;
    text-align: center;
    transform: translate(-50%, -50%);

    @media (width >= 360px) {
        left: 170px;
        width: 314px;
    }
`;

const MainTitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const MainTitleText = styled.span`
    margin-right: 10px;
    font-size: 5.3vw;
    font-weight: 900;
    white-space: nowrap;

    @media (width >= 360px) {
        font-size: 24px;
    }
`;

const RemainingDaysContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: flex-start;
    margin-top: 2px;
`;

const RemainingDaysText = styled.span`
    font-size: 10px;
    font-weight: 900;
`;

const DaysRemainingText = styled.span`
    font-size: 18px;
    font-weight: 900;
`;

const InfoIcon = styled.img<{
    $ableToPush: boolean;
}>`
    position: absolute;
    top: 8px;
    right: 20px;
    z-index: 5;
    width: 31px;
    height: 31px;
    pointer-events: ${(props) => (props.$ableToPush ? 'auto' : 'none')};
    cursor: pointer;
    filter: drop-shadow(1px 1px 3px rgb(0 0 0 / 22.4%));
    border: 3px solid #000000;
    border-radius: 50%;
    opacity: ${(props) => (props.$ableToPush ? 1 : 0.3)};
`;

const PlayerJimotoContainer = styled.div`
    position: relative;
    z-index: 4;
    height: 50px;
    background-color: white;
    border-radius: 0 45px 45px 0;
    box-shadow: 0 3px 3px 0 rgb(0 0 0 / 16%);
`;

const PlayerHomeTownContainer = styled.div`
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: row;
    gap: 2px;
    align-items: center;
    width: 90px;
    height: 100%;
    padding-left: 12px;
    background-color: #e40513;
    border-radius: 0 45px 45px 0;
`;

const LocationIcon = styled.img`
    width: 15px;
    height: 15px;
`;

const StageAreaName = styled.span`
    font-size: 14px;
    font-weight: 900;
    line-height: 13px;
    color: white;
    letter-spacing: 0.42px;
`;

const PlayerMilestoneContainer = styled.div`
    position: relative;
    top: -56px;
    z-index: 3;
    display: flex;
    flex-direction: column;
    gap: 4px;
    min-width: 70px;
    margin-left: 95px;
    font-weight: 900;
    color: black;
`;

const GoalTitleText = styled.div`
    margin-top: 15px;
    margin-left: 14px;
    font-size: 10px;
    font-weight: 900;
    text-align: left;
`;

const GoalNameText = styled.div`
    margin: 0 12px 0 14px;
    font-size: 12px;
    font-weight: 900;
`;

const PlayerListContainer = styled.div<{
    $ableToPush: boolean;
}>`
    position: relative;
    display: flex;
    gap: 4px;
    align-items: center;
    width: 205px;
    height: 50px;
    padding: 0 10px;
    pointer-events: ${(props) => (props.$ableToPush ? 'auto' : 'none')};
    cursor: pointer;
    background-color: #e40513;
    border-radius: 0 45px 45px 0;
    box-shadow: 0 3px 3px 0 rgb(0 0 0 / 16%);
    opacity: ${(props) => (props.$ableToPush ? 1 : 0.3)};
`;

const PlayersInfoDiary = styled.img`
    width: 25px;
    height: 25px;
    cursor: pointer;
`;

const PlayerListContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: flex-start;
`;

const PlayerListText = styled.span`
    font-size: 14px;
    font-weight: 900;
    color: white;
`;

const AvailablePlayerCount = styled.span`
    font-size: 12px;
    font-weight: bold;
    color: white;
`;

const WhiteArrowIcon = styled.img`
    width: 17px;
    height: 13px;
`;

const SaikoroContainer = styled.div<{
    $isSugorokuStarted: boolean;
}>`
    position: absolute;
    right: 0;
    bottom: 14px;
    z-index: 4;
    display: flex;
    flex-direction: column;
    width: 320px;
    overflow: hidden;
    filter: ${(props) =>
        props.$isSugorokuStarted ? 'none' : 'brightness(0.7)'};
    border: 3px solid #e40513;
    border-right: 0;
    border-radius: 21px 0 0 21px;
`;

const TopPart = styled.div`
    display: flex;
    gap: 12px;
    align-items: baseline;
    padding: 10px 0 10px 15px;
    font-size: 14px;
    font-weight: 900;
    color: white;
    background-color: #e40513;

    @media (width >= 406px) {
        font-size: 14px;
    }
`;

const RemainingDiceContainer = styled.div`
    display: flex;
    gap: 3px;
    align-items: baseline;
    font-family: Arial, sans-serif;
    font-size: 16px;
    font-weight: bold;
`;

const RemainingDiceCount = styled.span`
    font-size: 32px;
`;

const BottomPart = styled.div`
    display: grid;
    gap: 5px;
    align-items: baseline;
    padding: 8px 0 6px 15px;
    font-size: 13px;
    font-weight: 900;
    color: black;
    background-color: white;
`;

const BottomPartInformationContainer = styled.div`
    display: flex;
    gap: 10px;
`;

const BottomPartInformation = styled.div`
    display: flex;
    gap: 6px;
    align-items: baseline;
`;

const MileCount = styled.span`
    font-family: Arial, sans-serif;
    font-size: 18px;
    font-weight: bold;
    line-height: 1;
    color: #e40513;
    vertical-align: -1px;
`;

const DiceImage = styled.img`
    width: 50px;
    height: 50px;
`;

const Furu = styled.img`
    width: 50px;
    height: 27px;
`;

const RoundButton = styled.button<{ $ableToPush: boolean }>`
    position: absolute;
    right: calc(50% - 50vw);
    bottom: 70px;
    z-index: 6;
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    justify-content: center;
    width: 117px;
    height: 117px;
    font-size: 23px;
    font-weight: 900;
    color: #e40513;
    cursor: pointer;
    background-color: white;
    border: 2px solid rgb(0 0 0 / 60%);
    border-radius: 50%;
    ${(props) =>
        !props.$ableToPush &&
        `
        pointer-events: none;
    `}

    &::before {
        position: absolute;
        inset: 0;
        content: '';
        border: 5px solid #e40513;
        border-radius: 50%;
        ${(props) =>
            !props.$ableToPush &&
            `
            background-color: rgb(0, 0, 0, 30%);
            filter: brightness(0.7);
            pointer-events: none;
        `}
    }

    @media (width >= 360px) {
        right: calc(50% - 180px);
    }
`;

const TilePathAndFootballContainer = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 65%;

    @media (height <= 648px) {
        bottom: 60px;
    }
`;

const FootballImage = styled.img`
    position: absolute;
    top: -43px;
    left: 111px;
    z-index: 5;
    width: 45px;
    height: 45px;
    filter: drop-shadow(0 0 10px rgb(0 0 0 / 30%));

    @media (height <= 700px) {
        top: -10px;
    }

    @media (height <= 652px) {
        top: 80px;
    }
`;

/**
 * ダイアログ画像をラップするコンポーネント
 */
const DialogImageWrapper = styled.div`
    position: absolute;
    top: -85px;
    left: 50%;
    transform: translateX(-50%);
`;

const DialogImage = styled.img`
    width: 183px;
`;

const DialogTextContainer = styled.div`
    display: grid;
    row-gap: 10px;
    text-align: center;
`;

const DialogMainText = styled.span`
    font-size: 22px;
    font-weight: 900;
    line-height: 30px;
    color: #d71920;
`;

const DialogSubText = styled.span`
    font-size: 16px;
    font-weight: 900;
    line-height: 30px;
`;

const StyledButton = styled.button`
    width: 200px;
    padding: 13px 0;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 900;
    color: #000000;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid #000000;
    border-radius: 25px;

    /* アクセシビリティ的によくないが、最初からネガティブな選択肢にフォーカスがあたっているのが気に食わないので… */
    &:focus-visible {
        outline: none;
    }
`;

const OkButton = styled(StyledButton)`
    font-weight: bold;
    color: white;
    background-color: #d71920;
    border: 1px solid #d71920;
`;

const StartDateMessageContainer = styled.div`
    position: absolute;
    top: 49%;
    left: 50%;
    z-index: 7;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 215px;
    font-size: 25px;
    font-weight: bold;
    color: white;
    text-align: center;
    background-color: #d10411;
    transform: translate(-50%, -50%);
`;

const SugorokuDisabledMessageContainer = styled(StartDateMessageContainer)`
    font-size: 20px;
`;

const MyPageSugoroku: React.FC = () => {
    //TODO :initial値は０かnullだとエラー出たため1000
    const DEFAULT_CELLS_TO_GOAL = 1000;
    const [tilePosition, setTilePosition] = useState(0);
    const [tilePositionChange, setTilePositionChange] = useState(0);
    const [isRollingDice, setIsRollingDice] = useState(false);
    const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
    const [showDiceRollLoadingSpinner, setShowDiceRollLoadingSpinner] =
        useState(false);
    const [showPkScreen, setShowPkScreen] = useState(false);
    const [diceValue, setDiceValue] = useState(0);
    const [showDice, setShowDice] = useState(false);
    const [showPlayerList, setShowPlayerList] = useState(false);
    const [daysRemaining, setDaysRemaining] = useState(0);
    const [stageAreaName, setStageAreaName] = useState('');
    const [nextEventName, setNextEventName] = useState<
        string | null | undefined
    >('');
    const [hasError, setHasError] = useState(false);
    const [diceCount, setDiceCount] = useState(0);
    const [AvailablePlayers, setAvailablePlayers] = useState(0);
    const [AllPlayers, setAllPlayers] = useState(0);
    const [showPkDialog, setShowPkDialog] = useState(false);
    const [showHelpPage, setShowHelpPage] = useState(false);
    const [cellsToGoal, setCellsToGoal] = useState(DEFAULT_CELLS_TO_GOAL);
    const [isFinished, setIsFinished] = useState(false);
    const [isSugorokuStarted, setIsSugorokuStarted] = useState(true);
    const [nextEventItem, setNextEventItem] = useState<SugorokuItem | null>(
        null
    );
    const [remainingTiles, setRemainingTiles] = useState(1000);

    const handleRemainingTilesChange = (count: number) => {
        setRemainingTiles(count);
    };

    const isSugorokuDisabled =
        import.meta.env.VITE_IS_SUGOROKU_DISABLED === 'true';

    //最終ステージのis_finishedがtrueの場合、cellsToGoal値としてDEFAULT_CELLS_TO_GOAL表示されちゃうから０に設定にする
    if (cellsToGoal === DEFAULT_CELLS_TO_GOAL) {
        setCellsToGoal(0);
    }
    // フッターに「！」を表示するためのフラグを取得
    const [sugorokuMap, setSugorokuMap] = React.useState<SugorokuMaster | null>(
        null
    );
    const { setShowItemNoticeBadge } = useShowItemNoticeBadgeContext();
    const logout = useLogout();
    const [totalCells, setTotalCells] = useState<number>(0);
    const showPkDialogRef = useRef(showPkDialog);
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (!initialized) {
            ga4PushEvent(GA4_CUSTOM_EVENT.DISPLAY_SUGOROKU_MAP_PAGE);
        }
        setInitialized(true);
    }, [initialized]);

    useEffect(() => {
        showPkDialogRef.current = showPkDialog;
    }, [showPkDialog, cellsToGoal, remainingTiles]);

    useEffect(() => {
        // すごろくの開始日時を取得
        const startDatetime = new Date(
            sugorokuMap?.sugorokuMap?.startDatetime || ''
        );
        // 現在時刻がすごろくの開始日時以降であれば、すごろくが開始されたと判断する
        setIsSugorokuStarted(new Date() >= startDatetime);
    }, [sugorokuMap]);

    //最終の残りの3マス数の表示値を計算する関数には条件を追加(remainingTiles === 2&&remainingTiles === 1追加.
    //最終の３マスにはtilePosition無効なため
    const calculateRemainingTiles = (
        totalCells: number,
        tilePosition: number,
        remainingTiles: number
    ) => {
        if (remainingTiles === 2) {
            return 1;
        } else if (remainingTiles === 1) {
            return 0;
        } else {
            const distance = Math.abs(totalCells - tilePosition - 1);
            return distance;
        }
    };

    /**
     * これまでに進んだマスと今回進むマスを足し合わせる
     * @param tilePosition 現在いるマス数
     * @param remainingTiles 残りのマス数
     * @param totalCells 総マス数
     * @returns たされたマス数
     */
    const calculateTraversedCells = (
        totalCells: number,
        tilePosition: number,
        remainingTiles: number
    ) => {
        if (remainingTiles === 2) {
            return tilePosition + 2;
        } else if (remainingTiles === 1) {
            if (tilePosition + 3 > totalCells) {
                return tilePosition + 2;
            } else {
                return tilePosition + 3;
            }
        } else {
            return tilePosition + 1;
        }
    };

    const handleDiceRollComplete = (diceValue: number) => {
        setIsRollingDice(true);
        setDiceValue(diceValue);
        const positionChange = diceValue;
        setTilePositionChange(positionChange);
        setTimeout(() => {
            setTimeout(() => {
                setIsRollingDice(false);
                // 最新のshowPkDialogがTrueのときはダイスを非表示にしない
                // なぜこうしているかと言うと、不要なshowDiceの再代入によって再レンダリングがされてほしくないから
                if (!showPkDialogRef.current) {
                    setShowDice(false);
                }
            }, 1000);
        }, 3000);
    };

    const handleButtonClick = async () => {
        ga4PushEvent(GA4_CUSTOM_EVENT.PRESSED_ROLL_DICE_BUTTON);
        setShowDiceRollLoadingSpinner(true);
        try {
            const postUseDiceResult: UseDiceResult =
                await waitAsyncFunctionExecuted(postUseDice, 500);
            setShowDiceRollLoadingSpinner(false);
            setShowDice(true);
            setTimeout(() => {
                handleDiceRollComplete(postUseDiceResult.diceValue);
                setDiceCount(diceCount - 1);
                if (diceCount - 1 === 0) {
                    setShowItemNoticeBadge(false);
                }
            }, 110);
        } catch (error) {
            console.error('Failed to roll dice:', error);
            setShowDiceRollLoadingSpinner(false);
            setHasError(true);
        }
    };

    const handlePlayersInfoDiaryClick = () => {
        setShowPlayerList(true);
    };

    const calculateDaysRemaining = (endDate: string | undefined) => {
        const targetDate = endDate ? new Date(endDate) : new Date();
        const currentDate = new Date();
        const timeDiff = targetDate.getTime() - currentDate.getTime();
        const daysRemaining = Math.ceil(timeDiff / (1000 * 3600 * 24));
        return daysRemaining;
    };

    interface AccountSugorokuProgress {
        current_stage_code: string;
        current_cell: number;
        cells_to_goal: number;
        items: {
            code: string;
            club_player_code: string;
            quantity: number;
        }[];
    }

    const calculateAvailablePlayers = (
        accountSugorokuProgress: AccountSugorokuProgress | null
    ) => {
        if (accountSugorokuProgress?.items) {
            return accountSugorokuProgress.items.filter(
                (item) => item.club_player_code !== null
            ).length;
        }
        return 0;
    };

    const findNextStageEvent = (
        accountSugorokuProgress: AccountSugorokuProgress | null,
        mapEvents: SugorokuMaster
    ) => {
        if (accountSugorokuProgress && mapEvents && mapEvents.sugorokuMap) {
            const currentStageCode = accountSugorokuProgress.current_stage_code;
            if (currentStageCode) {
                const currentStage = mapEvents.sugorokuMap.stages.find(
                    (stage) => stage.code === currentStageCode
                );
                const currentCell = accountSugorokuProgress.current_cell || 0;
                if (currentStage?.events) {
                    const nextEvent = currentStage.events.find(
                        (event) => event.cell >= currentCell
                    );
                    return nextEvent;
                }
            }
        }
    };

    const getCellsToGoal = (
        accountSugorokuProgress: AccountSugorokuProgress | null
    ) => {
        if (typeof accountSugorokuProgress?.cells_to_goal === 'number') {
            return accountSugorokuProgress?.cells_to_goal ?? 0;
        }
        return 0;
    };

    const findStageGoal = (
        accountSugorokuProgress: AccountSugorokuProgress,
        nextStageEvent: SugorokuStageEvent
    ) => {
        const nextGoal = nextStageEvent.cell;
        return nextGoal - accountSugorokuProgress.current_cell;
    };

    const fetchStageAreaName = async (
        accountSugorokuProgress: AccountSugorokuProgress,
        sugorokuMaster: SugorokuMaster
    ): Promise<string> => {
        const currentStageCode = accountSugorokuProgress.current_stage_code;
        const mapStage = sugorokuMaster.sugorokuMap.stages.find(
            (stage) => stage.code === currentStageCode
        );
        const stageAreaName = mapStage?.areaName || 'Unknown';
        return stageAreaName;
    };

    const handleHelpIconClick = () => {
        setShowHelpPage(true);
    };

    const handlePkDialogButtonClick = () => {
        setShowPkDialog(false);
        setShowPkScreen(true);
    };

    React.useEffect(() => {
        const fetchSugorokuMaster = async () => {
            setShowLoadingSpinner(true);
            // すごろくできない状態ならAPIコールする意味なし
            if (isSugorokuDisabled) {
                setShowLoadingSpinner(false);
                return;
            }
            try {
                // 余り出目の取得
                const remainDiceValue =
                    SessionStorageManager.getSessionStorageValue(
                        SESSION_STORAGE_KEYS.MY_PAGE_SUGOROKU_REMAIN_DICE_VALUE
                    ) || 0;
                const remainDiceValueNumber = Number(remainDiceValue);

                const response = await getSugorokuMaster();
                SugorokuMasterManager.setSugorokuMaster(response);
                const mapEvents = SugorokuMasterManager.getMap();
                setSugorokuMap(mapEvents);
                if (!mapEvents) {
                    throw new Error('no maps returned');
                }
                const player = SugorokuMasterManager.getClubPlayers();
                const accountSugorokuProgress =
                    await getAccountSugorokuProgress();
                if (!accountSugorokuProgress) {
                    throw new Error('no account sugoroku progress returned');
                }
                setAllPlayers(player.length);

                // TODO この余り出目を使ってドーレくんを初回読み込み時からピョンピョンさせる実装を書く

                // ここで消しておかないと読み込むたびにドーレくんがピョンピョンしちゃうので
                SessionStorageManager.deleteSessionStorageValue(
                    SESSION_STORAGE_KEYS.MY_PAGE_SUGOROKU_REMAIN_DICE_VALUE
                );
                const daysRemaining = calculateDaysRemaining(
                    mapEvents?.sugorokuMap?.endDatetime
                );
                setDaysRemaining(daysRemaining);

                const availablePlayers = calculateAvailablePlayers(
                    accountSugorokuProgress
                );
                setAvailablePlayers(availablePlayers);

                const traversedCells =
                    accountSugorokuProgress.total_cells_traversed;
                setTilePosition(traversedCells - 1 - remainDiceValueNumber);
                const stageAreaName = await fetchStageAreaName(
                    accountSugorokuProgress,
                    mapEvents
                );
                setStageAreaName(stageAreaName);

                const diceCount = SugorokuItemManager.getDiceCount(
                    accountSugorokuProgress
                );
                setDiceCount(diceCount);

                const nextStageEvent = findNextStageEvent(
                    accountSugorokuProgress,
                    mapEvents
                );
                // TS用のおまじない
                if (!nextStageEvent) {
                    return;
                }
                const nextEventName = nextStageEvent.title;
                setNextEventName(nextEventName);

                const nextEventItem = nextStageEvent.item;
                setNextEventItem(nextEventItem);

                const cellsToGoalValue = getCellsToGoal(
                    accountSugorokuProgress
                );
                if (cellsToGoalValue > 0) {
                    setCellsToGoal(cellsToGoalValue - remainDiceValueNumber);
                }

                const stageGoal = findStageGoal(
                    accountSugorokuProgress,
                    nextStageEvent
                );

                setTotalCells(cellsToGoalValue + traversedCells);

                const isFinished = accountSugorokuProgress.is_finished;
                setIsFinished(isFinished);

                // 現在のtilePositionがステージベントマスと一致するかチェック
                // 余り出目がある場合はこのチェックをスキップし、FullTilePath側でsetShowPkDialogさせる。
                if (
                    remainDiceValueNumber === 0 &&
                    stageGoal === 0 &&
                    !isFinished
                ) {
                    setShowPkDialog(true);
                }

                if (isFinished) {
                    return;
                }
                // 余り出目分進ませる対応
                if (remainDiceValueNumber > 0) {
                    setDiceValue(remainDiceValueNumber);
                    setTilePositionChange(remainDiceValueNumber);
                }
            } catch (error: unknown) {
                setSugorokuMap(null);
                SugorokuMasterManager.clearSugorokuMaster();
                console.error('Failed to fetch sugoroku master:', error);
                handleAxiosError(logout, error);
            } finally {
                //FullTilePathのisTileBeforeLastの値をセットなるのが遅くて、
                //ドーレくんのローディング前になるはずの移動は画面で見えちゃうため、それを防ぐためローディング100ミリセック長くセット;
                setTimeout(() => {
                    setShowLoadingSpinner(false);
                }, 100);
            }
        };
        fetchSugorokuMaster();
        // 最初の1回だけ呼ばせたいのでignore
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    interface DynamicTextProps {
        daysRemaining: number;
    }

    const DynamicText: React.FC<DynamicTextProps> = ({ daysRemaining }) => {
        return (
            <ConftTitleContainer>
                <MainTitleContainer>
                    <MainTitleText>CONFTすごろく</MainTitleText>
                </MainTitleContainer>
                <RemainingDaysContainer>
                    <RemainingDaysText>ゲーム開催期間</RemainingDaysText>
                    <RemainingDaysText>
                        あと{' '}
                        <DaysRemainingText>
                            {daysRemaining > 0 && isSugorokuStarted
                                ? daysRemaining
                                : '-'}
                        </DaysRemainingText>
                        日
                    </RemainingDaysText>
                </RemainingDaysContainer>
            </ConftTitleContainer>
        );
    };

    const PkDialog: React.FC = () => {
        const { ref } = useDialog();

        return (
            <Dialog
                ref={ref}
                isOpen={true}
                DialogImageWrapper={
                    <DialogImageWrapper>
                        <DialogImage src={doleWakuwaku} alt="dolekun" />
                    </DialogImageWrapper>
                }
                closeModal={() => {}}
                showCloseButton={false}
            >
                <DialogTextContainer>
                    <DialogMainText>
                        {/* 一応「アイテム」というガードを入れているが、必要かどうかはわからない */}
                        {nextEventItem?.clubPlayerPresentationName
                            ? `${nextEventItem?.clubPlayerPresentationName}の㊙情報`
                            : 'アイテム'}
                        <br />
                        獲得のチャンス！
                    </DialogMainText>
                    <DialogSubText>3つの的からアタリを選ぼう！</DialogSubText>
                </DialogTextContainer>
                <OkButton onClick={handlePkDialogButtonClick}>
                    ミニゲームへ進む
                </OkButton>
            </Dialog>
        );
    };

    /**
     * すごろく開始期間外のメッセージ
     * ※ 都度変更すること
     */
    const StartDateMessage: React.FC = () => {
        return (
            <StartDateMessageContainer>
                CONFTすごろくの開始は
                <br />
                2024.8.10 08:10 からです！！
            </StartDateMessageContainer>
        );
    };

    /**
     * すごろくを環境変数で停止している場合のメッセージ
     */
    const SugorokuDisabledMessage: React.FC = () => {
        return (
            <SugorokuDisabledMessageContainer>
                誠に申し訳ございません。
                <br />
                <br />
                ただいま問題が発生したため
                <br />
                すごろくゲームを停止しております。
                <br />
                <br />
                復旧までお待ちください。
            </SugorokuDisabledMessageContainer>
        );
    };

    return (
        <>
            {hasError && <SugorokuErrorDialog />}
            {showLoadingSpinner && <LoadingSpinnerOverlay opacity={1} />}
            {showDiceRollLoadingSpinner && (
                <LoadingSpinnerOverlay opacity={0.6} />
            )}
            {showPkDialog && <PkDialog />}
            {!showPkDialog && !showPkScreen && showDice && (
                <DiceRoll
                    handleRoll={handleDiceRollComplete}
                    diceValue={diceValue}
                    isRollingDice={isRollingDice}
                    setIsRollingDice={setIsRollingDice}
                />
            )}
            {showPkScreen && nextEventItem ? (
                <PkScreen nextEventItem={nextEventItem} />
            ) : showPlayerList ? (
                <PlayerList />
            ) : showHelpPage ? (
                <SugorokuHelp />
            ) : (
                <Container>
                    <InfoIcon
                        $ableToPush={
                            !isSugorokuDisabled &&
                            !showDice &&
                            tilePositionChange === 0
                        }
                        src={infoIcon}
                        onClick={handleHelpIconClick}
                        alt="infoIcon"
                    />
                    <SkyBackground />
                    <CloudsOverlay />
                    <SecondCloudOverlay />
                    {isSugorokuDisabled ? (
                        <SugorokuDisabledMessage />
                    ) : (
                        !isSugorokuStarted && <StartDateMessage />
                    )}
                    <GreenaryBackground>
                        <TilePathAndFootballContainer>
                            {!isSugorokuDisabled && isSugorokuStarted && (
                                <>
                                    <FullTilePath
                                        numTiles={totalCells}
                                        cellsToGoalValue={cellsToGoal}
                                        tilePosition={tilePosition}
                                        diceValue={diceValue}
                                        isRollingDice={isRollingDice}
                                        setTilePosition={setTilePosition}
                                        tilePositionChange={tilePositionChange}
                                        setTileChangePositionChange={
                                            setTilePositionChange
                                        }
                                        showPkDialog={showPkDialog}
                                        setShowPkDialog={setShowPkDialog}
                                        sugorokuMaster={SugorokuMasterManager.getMap()}
                                        clubPlayers={SugorokuMasterManager.getClubPlayers()}
                                        isFinished={isFinished}
                                        onRemainingTilesChange={
                                            handleRemainingTilesChange
                                        }
                                    />
                                    <FootballImage
                                        src={Football}
                                        alt="Football"
                                    />
                                </>
                            )}
                        </TilePathAndFootballContainer>
                    </GreenaryBackground>
                    <MainContentContainer>
                        <CircularRectangleBoxContainer>
                            <DynamicText daysRemaining={daysRemaining} />
                        </CircularRectangleBoxContainer>
                        <PlayerJimotoContainer>
                            <PlayerHomeTownContainer>
                                <LocationIcon src={location} alt="Location" />
                                <StageAreaName>
                                    {isFinished ||
                                    isSugorokuDisabled ||
                                    !isSugorokuStarted
                                        ? '-'
                                        : stageAreaName}
                                </StageAreaName>
                            </PlayerHomeTownContainer>
                            <PlayerMilestoneContainer>
                                <GoalTitleText>次の目標</GoalTitleText>
                                <GoalNameText>
                                    {isFinished
                                        ? 'ゴールおめでとう 🎉'
                                        : !isSugorokuStarted ||
                                            isSugorokuDisabled
                                          ? '-'
                                          : nextEventName}
                                </GoalNameText>
                            </PlayerMilestoneContainer>
                        </PlayerJimotoContainer>
                        <PlayerListContainer
                            $ableToPush={
                                !showDice &&
                                isSugorokuStarted &&
                                !isSugorokuDisabled &&
                                tilePositionChange === 0 // PKの余り出目分進んだ場合の対策
                            }
                            onClick={handlePlayersInfoDiaryClick}
                        >
                            <PlayersInfoDiary
                                src={playersInfoDiary}
                                alt="playersInfoDiary"
                            />
                            <PlayerListContent>
                                <PlayerListText>
                                    獲得した選手情報を見る
                                </PlayerListText>
                                <AvailablePlayerCount>
                                    {isSugorokuStarted && !isSugorokuDisabled
                                        ? `${AvailablePlayers}/${AllPlayers}`
                                        : '-/-'}
                                </AvailablePlayerCount>
                            </PlayerListContent>
                            <WhiteArrowIcon
                                src={WhiteArrow}
                                alt="White Arrow"
                            />
                        </PlayerListContainer>
                    </MainContentContainer>
                    <SaikoroContainer $isSugorokuStarted={isSugorokuStarted}>
                        <TopPart>
                            サイコロ残数
                            <RemainingDiceContainer>
                                <RemainingDiceCount>
                                    {isFinished ||
                                    !isSugorokuStarted ||
                                    isSugorokuDisabled
                                        ? '-'
                                        : diceCount}
                                </RemainingDiceCount>
                                個
                            </RemainingDiceContainer>
                        </TopPart>
                        <BottomPart>
                            選手の地元を巡っていこう！
                            <BottomPartInformationContainer>
                                <BottomPartInformation>
                                    現在地
                                    <div>
                                        <MileCount>
                                            {isFinished
                                                ? tilePosition + 1
                                                : !isSugorokuStarted ||
                                                    isSugorokuDisabled
                                                  ? '-'
                                                  : calculateTraversedCells(
                                                        totalCells,
                                                        tilePosition,
                                                        remainingTiles
                                                    )}
                                        </MileCount>{' '}
                                        マス目
                                    </div>
                                </BottomPartInformation>
                                <BottomPartInformation>
                                    残りマス
                                    <div>
                                        <MileCount>
                                            {isFinished
                                                ? 0
                                                : !isSugorokuStarted ||
                                                    isSugorokuDisabled
                                                  ? '-'
                                                  : calculateRemainingTiles(
                                                        totalCells,
                                                        tilePosition,
                                                        remainingTiles
                                                    )}
                                        </MileCount>{' '}
                                        マス
                                    </div>
                                </BottomPartInformation>
                            </BottomPartInformationContainer>
                        </BottomPart>
                    </SaikoroContainer>
                    <RoundButton
                        onClick={handleButtonClick}
                        $ableToPush={
                            !isFinished &&
                            isSugorokuStarted &&
                            !isSugorokuDisabled &&
                            diceCount > 0 &&
                            !showDice &&
                            !showPkDialog &&
                            tilePositionChange === 0 // PKの余り出目分進んだ場合の対策
                        }
                    >
                        <DiceImage src={diceSvg} alt="dice" />
                        <Furu src={furu} alt="furu" />
                    </RoundButton>
                </Container>
            )}
        </>
    );
};

export default MyPageSugoroku;
