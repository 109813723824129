import { createAxiosInstance } from '../lib/createAxiosInstance';
import { API_PATHS } from './constants/apiPaths';
import { NftDetail } from '../types/nftDetailType';
import { nftDetailValidator } from './validators/nftDetailValidator';
import { GetNftDetailResponseBody } from './types/getNftDetailType';
import { AxiosResponse } from 'axios';

/**
 * NFT詳細取得APIを呼び出す
 * @returns {Nft} NFT詳細情報
 * @throws {Error} API呼び出しに失敗した場合にエラーを投げる
 */
export const getNftDetail = async (eventId: number): Promise<NftDetail> => {
    try {
        const instance = createAxiosInstance();
        const response: AxiosResponse = await instance.get(
            API_PATHS.GET_NFT_DETAIL(eventId)
        );
        const nftDetailResponse: GetNftDetailResponseBody = response.data ?? {};

        const convertedNftDetail: NftDetail = {
            event: {
                id: nftDetailResponse.event.id,
                name: nftDetailResponse.event.name,
                shortName: nftDetailResponse.event.short_name,
                date: nftDetailResponse.event.date,
                color: nftDetailResponse.event.color,
                canPostTrajectory: nftDetailResponse.event.can_post_trajectory,
                previousId: nftDetailResponse.event.previous_id,
                nextId: nftDetailResponse.event.next_id,
            },
            accountNftId: nftDetailResponse.account_nft_id,
            imageUrl: nftDetailResponse.image_url,
            nftAddress: nftDetailResponse.nft_address,
            description: nftDetailResponse.description,
        } as NftDetail;

        const isValid: boolean = nftDetailValidator(convertedNftDetail);
        if (!isValid) {
            throw Error;
        }

        return convertedNftDetail;
    } catch (error) {
        console.error('Error fetching nfts:', error);
        throw error;
    }
};
