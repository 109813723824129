import EventType from "../../types/event";

export const eventValidator = (event: EventType) => {
    if (!event) {
        return false;
    }
    if (!event.id) {
        return false;
    }
    if (!event.date) {
        return false;
    }
    if (!event.name) {
        return false;
    }
    return true;
};
