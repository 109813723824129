import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import tileImage from '../assets/images/greytile.svg';
import tileGold from '../assets/images/goldtile.svg';
import dolekunWalk from '../assets/images/dole.png';
import nameBoard from '../assets/images/name_board.svg';
import { SugorokuMaster, ClubPlayers } from '../types/sugorokuMasterType';

const DOLE_POSITION_LAST: number = 296;
const DOLE_POSITION_BEFORE_LAST: number = 175;

const sweepAnimation = keyframes`
    0% {
        left: 0px;
    }
    100% {
        left: -235px;
    }
`;

interface TilePathContainerProps {
    $isSweeping: boolean;
    $numTiles: number;
    $cellsToGoal: number;
    $tilePosition: number;
    $leftTilesCount: number;
}

const TilePathContainer = styled.div<TilePathContainerProps>`
    position: absolute;
    bottom: 40dvh;
    left: ${(props) =>
        calculateTileLeftPosition(
            props.$numTiles,
            props.$cellsToGoal,
            props.$tilePosition
        )}px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow: hidden;
    transform: translateX(-59%);

    @media (height <= 700px) {
        bottom: 32dvh;
    }
`;

interface TileContainerProps {
    $isSweeping: boolean;
}

const TileContainer = styled.div<TileContainerProps>`
    position: relative;
    display: flex;
    width: 120px;
    height: 220px;
    animation: ${({ $isSweeping }) => ($isSweeping ? sweepAnimation : 'none')};
    animation-duration: 1s;
    animation-delay: 0s;
    backface-visibility: hidden;
`;

interface TileProps {
    $isNameBoardTile?: boolean;
}

const RegularTile = styled.div<TileProps>`
    position: absolute;
    bottom: 0;
    width: 116px;
    height: 72px;
    background-image: url(${tileImage});
    background-repeat: no-repeat;
`;

const GreenTile = styled.div<TileProps>`
    position: absolute;
    bottom: 0;
    width: 116px;
    height: 72px;
    background-image: url(${tileGold});
    background-repeat: no-repeat;
`;

// DEBUG しやすいためキープします。
// const TileNumber = styled.div`
//     position: absolute;
//     bottom: 10px;
//     left: 50%;
//     font-size: 24px;
//     font-weight: bold;
//     color: white;
//     text-shadow: 1px 1px 2px rgb(0 0 0 / 50%);
//     transform: translateX(-50%);
// `;

interface DolekunWrapperProps {
    $isFinished: boolean;
    $isTileBeforeLast: boolean;
    $isTileLast: boolean;
    $dolePosition?: number;
}

const DolekunWrapper = styled.div<DolekunWrapperProps>`
    position: absolute;
    bottom: 44dvh;
    left: ${(props) =>
        props.$isFinished //最終ステージのisFinishedがtrueの場合
            ? '305px'
            : props.$isTileBeforeLast //残り2マスの場合
              ? `175px`
              : props.$isTileLast //残り1マスの場合
                ? `296px`
                : props.$dolePosition //dolePosition設定してる場合は
                  ? `175px`
                  : `55px`};
    z-index: 5;
    transform: translateX(-50%);

    @media (height <= 700px) {
        bottom: 37dvh;
    }
`;
interface DolekunIconProps {
    $dolePosition: number;
}

const DolekunIcon = styled.img<DolekunIconProps>`
    position: relative;
    left: ${({ $dolePosition }) =>
        $dolePosition === DOLE_POSITION_LAST ? '125px' : '10px'};
    z-index: 10;
    height: 140px;
    backface-visibility: hidden;

    /* おまじない */
    transform: translateZ(1px);
    perspective: 0;
`;

const jumpAnimation = keyframes`
  0% {
    top: 0;
  }
  50% {
    top: -20px;
  }
  100% {
    top: 0;
  }
`;

const diagonalJumpAnimation = keyframes`
  0% {
    top: 0;
    left: 55px;
  }
  50% {
    top: -35px;
    left: 120px;
  }
  100% {
    top: 0;
    left: 127px;
  }
`;

const JumpingDolekunIcon = styled(DolekunIcon)`
    animation: ${jumpAnimation} 0.4s;
    animation-delay: 0s;
`;

interface DiagonalJumpingDolekunIconProps extends DolekunWrapperProps {
    $dolePosition: number;
}

const DiagonalJumpingDolekunIcon = styled(
    DolekunIcon
)<DiagonalJumpingDolekunIconProps>`
    left: ${({ $dolePosition }) => ($dolePosition === 175 ? '55px' : '175px')};
    animation: ${diagonalJumpAnimation} 0.4s forwards ease-in-out;
    animation-delay: 0s;
    backface-visibility: hidden;
`;

const NameBoard = styled.img`
    position: absolute;
    top: 60px;
    height: 154px;
    transform: translate(0%, -35%);
`;

const MilestoneName = styled.div`
    position: absolute;
    top: 18%;
    width: 107px;
    font-size: 16px;
    font-weight: bold;
    color: black;
    text-align: center;
    white-space: nowrap;
`;

interface NumberedTileProps {
    tileNumber: number;
    isNameBoardTile: boolean;
    milestoneName: string;
    isSweeping: boolean;
    tilePosition: number;
}

const NumberedTile: React.FC<NumberedTileProps> = ({
    // tileNumber,
    isNameBoardTile,
    milestoneName,
    isSweeping,
}) => {
    return (
        <TileContainer $isSweeping={isSweeping}>
            {isNameBoardTile ? (
                <GreenTile $isNameBoardTile={isNameBoardTile} />
            ) : (
                <RegularTile $isNameBoardTile={isNameBoardTile} />
            )}
            {/* <TileNumber>{tileNumber}</TileNumber> */}
            {isNameBoardTile && (
                <>
                    <NameBoard src={nameBoard} alt="Name Board" />
                    <MilestoneName>{milestoneName}</MilestoneName>
                </>
            )}
        </TileContainer>
    );
};

const TILE_WIDTH = 120;
const FIRST_TILE_LEFT_POSITION = 160; // 2つのタイルの左側の初期値

function calculateFirstTileLeftPosition(numTiles: number): number {
    // 少なくとも２マスがある選定したため、上記で２マスの値は初期値として設定
    if (numTiles === 2) {
        return FIRST_TILE_LEFT_POSITION;
    } else {
        // 各追加のタイルに対して70.8ピクセルずつ増加させる。
        // タイル一個追加したら70.8ピクセルでタイルコンテナーは右に移動しちゃうことを防ぐため。
        return FIRST_TILE_LEFT_POSITION + (numTiles - 2) * 70.8;
    }
}

function calculateTileLeftPosition(
    numTiles: number,
    cellsToGoal: number,
    $tilePosition: number
): number {
    const initialTileIndex = numTiles - cellsToGoal - 1;

    const currentTileIndex = $tilePosition || initialTileIndex;
    const firstTileLeftPosition = calculateFirstTileLeftPosition(numTiles);
    if (cellsToGoal === 0) {
        // ゴールに到達した場合、ゴールの位置に2マスの２４０ピクセルで移動する
        return 240 + firstTileLeftPosition - currentTileIndex * TILE_WIDTH;
    } else if (cellsToGoal === 1) {
        return 120 + firstTileLeftPosition - currentTileIndex * TILE_WIDTH;
    } else {
        return firstTileLeftPosition - currentTileIndex * TILE_WIDTH;
    }
}

interface FullTilePathProps {
    numTiles: number;
    cellsToGoalValue: number;
    tilePosition: number;
    diceValue: number;
    isRollingDice: boolean;
    tilePositionChange: number;
    setTileChangePositionChange: React.Dispatch<React.SetStateAction<number>>;
    setTilePosition: React.Dispatch<React.SetStateAction<number>>;
    showPkDialog: boolean;
    setShowPkDialog: React.Dispatch<React.SetStateAction<boolean>>;
    sugorokuMaster: SugorokuMaster | null;
    clubPlayers: ClubPlayers[];
    isFinished: boolean;
    onRemainingTilesChange: (remainingTiles: number) => void;
}

const FullTilePath: React.FC<FullTilePathProps> = ({
    numTiles,
    cellsToGoalValue,
    tilePosition,
    diceValue,
    isRollingDice,
    tilePositionChange,
    setTileChangePositionChange,
    setTilePosition,
    showPkDialog,
    setShowPkDialog,
    sugorokuMaster,
    clubPlayers,
    isFinished,
    onRemainingTilesChange,
}) => {
    const [isSweeping, setIsSweeping] = useState(false);
    const [isJumping, setIsJumping] = useState(false);
    const newPositionRef = useRef(tilePosition);
    const leftTilesCountRef = useRef(numTiles - 1);
    const [isTileBeforeLast, setIsTileBeforeLast] = useState(false);
    const [isTileLast, setIsTileLast] = useState(false);
    const [dolePosition, setDolePosition] = useState(0);
    const [hasExecutedOnce, setHasExecutedOnce] = useState(false);

    useEffect(() => {
        newPositionRef.current = tilePosition;
        setIsTileBeforeLast(leftTilesCountRef.current === 1);
        setIsTileLast(leftTilesCountRef.current === 0);
    }, [tilePosition, leftTilesCountRef.current]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        leftTilesCountRef.current = numTiles - newPositionRef.current - 1;

        if (
            tilePositionChange !== 0 &&
            diceValue &&
            !isRollingDice &&
            !showPkDialog
        ) {
            const sweepLoop = async () => {
                for (let i = 0; i < Math.abs(tilePositionChange); i++) {
                    if (
                        leftTilesCountRef.current <= 2 &&
                        leftTilesCountRef.current > 0
                    ) {
                        setIsJumping(true);
                        await new Promise((resolve) =>
                            setTimeout(resolve, 300)
                        );
                        onRemainingTilesChange(leftTilesCountRef.current);
                        if (leftTilesCountRef.current === 1) {
                            setDolePosition(DOLE_POSITION_LAST);
                            await new Promise((resolve) =>
                                setTimeout(resolve, 300)
                            );
                            setIsJumping(false);
                            setShowPkDialog(true);
                            break;
                        } else if (
                            leftTilesCountRef.current === 2 &&
                            // ドーレくんは最後の一つ前のタイルににいて最終タイルにジャンプする際に二回ジャンプする不具合がありまして、
                            //  else if通ってまた次のループでifのはいるため起るエラーだとわかりました。else ifを通ることを防ぐ修正でした。
                            // ページのリロードやまた再ログイン時ドーレくんは最後の一つ前のタイルにいるとき起こらないエラーだとわかりました。
                            !hasExecutedOnce
                        ) {
                            setDolePosition(DOLE_POSITION_BEFORE_LAST);
                            setIsJumping(false);

                            //
                            setHasExecutedOnce(true);
                        }
                        leftTilesCountRef.current--;

                        await new Promise((resolve) =>
                            setTimeout(resolve, 100)
                        );
                    } else if (leftTilesCountRef.current >= 3) {
                        setIsSweeping(true);
                        setIsJumping(true);
                        await new Promise((resolve) =>
                            setTimeout(resolve, 300)
                        );
                        onRemainingTilesChange(leftTilesCountRef.current);

                        leftTilesCountRef.current--;
                        setTilePosition((prevPosition) => {
                            const newPosition = prevPosition + 1;
                            return newPosition;
                        });
                        setIsSweeping(false);
                        setIsJumping(false);
                        await new Promise((resolve) =>
                            setTimeout(resolve, 100)
                        );
                    }

                    if (
                        nameBoardTileIndices.some(
                            (index) => index === newPositionRef.current
                        ) &&
                        !isFinished
                    ) {
                        setShowPkDialog(true);
                        break;
                    }
                }
                // 「進むべきマス数」をリセット
                setTileChangePositionChange(0);
            };
            sweepLoop();
        }
        // nameBoardTileIndicesをたせと言われるが、足すとバグるのでたさない。
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        diceValue,
        isRollingDice,
        showPkDialog,
        cellsToGoalValue,
        tilePositionChange,
        numTiles,
        isRollingDice,
        showPkDialog,
        setIsJumping,
        setShowPkDialog,
        setTilePosition,
        leftTilesCountRef,
    ]);

    // Calculate the nameBoardTileIndices based on the sugorokuMaster data
    const nameBoardTileIndices = useMemo(() => {
        if (
            sugorokuMaster &&
            sugorokuMaster.sugorokuMap &&
            sugorokuMaster.sugorokuMap.stages
        ) {
            let totalCells = 0;
            return sugorokuMaster.sugorokuMap.stages.flatMap((stage) => {
                const stageEvents = stage.events || [];
                const stageIndices = stageEvents.map(
                    (event) => event.cell + totalCells - 1
                );
                totalCells += stage.totalCells;
                return stageIndices;
            });
        }
        return [];
    }, [sugorokuMaster]);

    // Generate the tiles with the correct isNameBoardTile values and milestone names
    const tileMilestonePath = useMemo(() => {
        const milestoneTitles =
            sugorokuMaster?.sugorokuMap?.stages.flatMap((stage) => {
                const stageEvents = stage.events || [];
                return stageEvents.map((event) => ({
                    title: event.title,
                    clubPlayerCode: event.item?.clubPlayerCode,
                }));
            }) || [];

        return [
            ...Array.from({ length: numTiles }, (_, index) => {
                const milestoneTitleInfo = milestoneTitles.find(
                    (_, i) =>
                        nameBoardTileIndices.includes(index) &&
                        i === nameBoardTileIndices.indexOf(index)
                );
                const clubPlayerCode = milestoneTitleInfo?.clubPlayerCode;
                const clubPlayer = clubPlayers.find(
                    (player) => player.code === clubPlayerCode
                );
                return {
                    tileNumber: index + 1,
                    isNameBoardTile: nameBoardTileIndices.includes(index),
                    milestoneName:
                        clubPlayer?.numberedHomeTown ||
                        clubPlayer?.hometown ||
                        'Unknown',
                };
            }),
        ];
    }, [numTiles, nameBoardTileIndices, sugorokuMaster, clubPlayers]);

    return (
        <>
            <TilePathContainer
                $numTiles={numTiles}
                $cellsToGoal={cellsToGoalValue}
                $isSweeping={isSweeping}
                $tilePosition={tilePosition}
                $leftTilesCount={leftTilesCountRef.current}
            >
                {tileMilestonePath.map((tile, index) => (
                    <NumberedTile
                        key={index}
                        tileNumber={index + 1}
                        isNameBoardTile={tile.isNameBoardTile}
                        milestoneName={tile.milestoneName}
                        isSweeping={isSweeping}
                        tilePosition={tilePosition}
                    />
                ))}
            </TilePathContainer>
            <DolekunWrapper
                $isFinished={isFinished}
                $isTileBeforeLast={isTileBeforeLast}
                $isTileLast={isTileLast}
                $dolePosition={dolePosition}
            >
                {isJumping ? (
                    leftTilesCountRef.current < 3 ? (
                        <DiagonalJumpingDolekunIcon
                            $dolePosition={dolePosition}
                            $isFinished={isFinished}
                            $isTileBeforeLast={isTileBeforeLast}
                            $isTileLast={isTileLast}
                            src={dolekunWalk}
                            alt="Dolekun"
                        />
                    ) : (
                        <JumpingDolekunIcon
                            $dolePosition={dolePosition}
                            src={dolekunWalk}
                            alt="Dolekun"
                        />
                    )
                ) : (
                    <DolekunIcon
                        $dolePosition={dolePosition}
                        src={dolekunWalk}
                        alt="Dolekun"
                    />
                )}
            </DolekunWrapper>
        </>
    );
};

export default FullTilePath;
