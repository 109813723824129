import Nft from '../../types/nftType';

export const nftValidator = (nft: Nft): boolean => {
    if (!nft) {
        return false;
    }
    if (!nft.event) {
        return false;
    }
    const { event } = nft;
    if (!event.id) {
        return false;
    }
    if (!event.name) {
        return false;
    }
    if (!event.color) {
        return false;
    }
    if (!event.shortName) {
        return false;
    }
    if (!event.date) {
        return false;
    }
    if (typeof event.canPostTrajectory !== 'boolean') {
        return false;
    }
    return true;
};

export const nftDetailValidator = (nft: Nft): boolean => {
    const isValid: boolean = nftValidator(nft);
    return isValid;
};
