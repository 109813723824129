import React, { useState } from 'react';
import styled from 'styled-components';
import NftStamp from '../components/NftStamp';
import { NftLoaderData } from '../types/nftLoaderData';
import { ga4PushEvent } from '../ga4';
import { GA4_CUSTOM_EVENT } from '../constants/ga4CustomEvent';
import noimage from '../assets/images/noimage.jpg';
import dolekunWait from '../assets/images/dolekun-wait.png';
import { Await, useLoaderData } from 'react-router-dom';
import { LoadingSpinnerOverlay } from '../Spinner';
import ReactCardFlip from 'react-card-flip';
import Nft from '../types/nftType';
// 写真・日記アイコンはPh1時点では蓋締めされていなくてはいけないため、一旦コメントアウト
import diaryIcon from '../assets/images/icon_diary.svg';
import picturesIcon from '../assets/images/icon_img.svg';
import { EventName } from '../components/EventName';
import EventLink from '../components/EventLink';
import DateFormatter from '../lib/dateFormatter';
import { isPWAEnabled } from '../lib/isPWAEnabled';
import { isMobile } from 'react-device-detect';
import { useSwipeable } from 'react-swipeable';
import { CustomSwipeableOptions } from '../types/swipeableOptions';

// NFT来場履歴のコンテナを定義
const NFTAttendanceContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: ${() => (isPWAEnabled() && isMobile ? '20px' : '40px')};
`;

const NFTAttendanceWrapper = styled.div`
    box-sizing: border-box;
    width: 100%;
    max-width: 400px;
    padding: 0 20px 20px;
`;

// NFT来場履歴のレコードをラップ
const NFTAttendanceContent = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    width: 100%;
`;

// 「NFT来場履歴がありません。」テキストのスタイル
const Text = styled.p`
    max-width: 400px;
    margin-top: 20px;
    font-size: 14px;
    text-align: center;
`;

// ドーレくん(まってます)画像のコンテナを定義
const DolekunWaitContainer = styled.div`
    margin: 40px 0;
    text-align: center;
`;

// ドーレくん(まってます)画像のスタイル
const DolekunWaitImage = styled.img`
    width: 70vw;
    max-width: 300px;
`;

const NFTAttendanceContentWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: ${() => (isPWAEnabled() && isMobile ? '20px 20px' : '30px 20px')};
    place-items: center center;

    @media (width < 375px) {
        grid-template-columns: 1fr;
    }

    & .react-card-flip {
        max-width: 157px;
    }
`;

const SupportTrackText = styled.h2`
    margin: ${() =>
        isPWAEnabled() && isMobile ? '40px 0px 20px' : '40px 0px'};
    font-size: 18px;
    font-weight: bold;
    text-align: center;
`;

const DateText = styled.span`
    display: block;
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    color: #848487;
`;

const DateAndIconsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
`;

const IconWrapper = styled.div<{ color: string }>`
    svg {
        width: 15px;
        height: 15px;
        fill: ${(props) => props.color};
    }

    margin-left: 7px;
`;

const IconContainer = styled.div`
    display: flex;
    min-height: 19px;
`;

const ContentText = styled.span`
    display: block;
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
`;

type TabProps = {
    disabled?: boolean;
};

const Tab = styled.div<TabProps>`
    width: 100px;
    padding: 3px;
    font-size: 14px;
    font-weight: ${(props) => (props.disabled ? 'bold' : 'normal')};
    line-height: 18px;
    color: ${(props) => (props.disabled ? 'black' : '#7D7D7D')};
    text-align: center;
    cursor: pointer;
`;

const Separator = styled.div`
    margin: 0 40px;
    color: #aaaaaa;
`;

const TabContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledImage = styled.img`
    height: 230px;
    box-shadow: 3px 3px 6px rgba(0 0 0/60%);
`;

const NFTContainer = styled.div`
    width: 157px;
    height: 230px;
`;

const NFTWrapper = styled.div`
    display: grid;
    grid-template-rows: subgrid;
    grid-row: span 2;
    row-gap: 0;
`;

/**
 * `DeferredNft`は、NFTデータを取得し表示するReactの関数コンポーネントです。
 * データの取得には`useLoaderData`フックを、フリップの処理には`useState`を使用しています。
 * データの取得中にエラーが発生した場合、ルートパスにナビゲートしエラーメッセージを表示します。
 *
 * @component
 * @example
 * return (
 *   <DeferredNft />
 * )
 */
const DeferredNft: React.FC = () => {
    const [isFlipped, setIsFlipped] = useState(true);

    //スマホの際の横スワイプできるようになる実装。ライブラリーを使用。
    const swipeHandler = useSwipeable({
        onSwipedLeft: () => flipToNFTList(),
        onSwipedRight: () => flipToAttendanceHistory(),
        onSwipedDown: () => {},
        onSwipedUp: () => {},
        delta: 50,
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
    } as CustomSwipeableOptions);

    // 来場証明の画面にフリップする関数
    const flipToAttendanceHistory = () => {
        setIsFlipped(true);
    };

    // NFT一覧の画面にフリップする関数
    const flipToNFTList = () => {
        setIsFlipped(false);
    };

    const data = useLoaderData() as NftLoaderData;
    const loadedNfts = data.nfts;

    const PictureIcon = ({ color = 'currentColor' }) => (
        <IconWrapper color={color}>
            <svg viewBox="0 0 34 23.476">
                <use xlinkHref={`${picturesIcon}#icon__img`} />
            </svg>
        </IconWrapper>
    );

    const DiaryIcon = ({ color = 'currentColor' }) => (
        <IconWrapper color={color}>
            <svg viewBox="0 0 31.982 31.044">
                <use xlinkHref={`${diaryIcon}#icon__diary`} />
            </svg>
        </IconWrapper>
    );

    React.useEffect(() => {
        ga4PushEvent(GA4_CUSTOM_EVENT.DISPLAY_ATTENDANCE_HISTORY_PAGE);
    }, []);

    /**
     * `renderNfts`はNFTオブジェクトの配列を引数に取り、JSXフラグメントを返す関数です。
     * 配列内の各NFTオブジェクトに対して、NFTのプロパティをプロップとして`NftStamp`コンポーネントを作成します。
     *
     * @param {Nft[]} nfts - NFTオブジェクトの配列。
     * @returns {JSX.Element} 配列内の各NFTに対する`NftStamp`コンポーネントを含むJSXフラグメント。
     */
    const renderNfts = (nfts: Nft[]) => {
        return (
            <>
                {nfts.map((nft, index) => (
                    <NftStamp key={index} event={nft.event} />
                ))}
            </>
        );
    };

    return (
        <React.Suspense fallback={<LoadingSpinnerOverlay />}>
            <Await resolve={loadedNfts}>
                {(loadedNfts: Nft[]) => {
                    return (
                        <div {...swipeHandler}>
                            <SupportTrackText>応援の軌跡</SupportTrackText>
                            <TabContainer>
                                <Tab
                                    onClick={flipToAttendanceHistory}
                                    disabled={isFlipped}
                                >
                                    履歴<br></br>（スタンプ）
                                </Tab>
                                <Separator>|</Separator>
                                <Tab
                                    onClick={flipToNFTList}
                                    disabled={!isFlipped}
                                >
                                    来場証明<br></br>（NFT）
                                </Tab>
                            </TabContainer>
                            <NFTAttendanceContainer>
                                {!loadedNfts || loadedNfts.length === 0 ? (
                                    <>
                                        <Text>履歴がありません。</Text>
                                        <DolekunWaitContainer>
                                            <DolekunWaitImage
                                                src={dolekunWait}
                                                alt="ドーレくん(まってます)"
                                            />
                                        </DolekunWaitContainer>
                                    </>
                                ) : (
                                    <NFTAttendanceWrapper>
                                        <NFTAttendanceContent>
                                            <NFTAttendanceContentWrapper>
                                                {loadedNfts.map(
                                                    (nft, index) => (
                                                        <NFTWrapper key={index}>
                                                            <ReactCardFlip
                                                                isFlipped={
                                                                    isFlipped
                                                                }
                                                                flipDirection="horizontal"
                                                            >
                                                                <div>
                                                                    <EventLink
                                                                        eventId={
                                                                            nft
                                                                                .event
                                                                                .id
                                                                        }
                                                                        ga4CustomEvent={
                                                                            GA4_CUSTOM_EVENT.PRESSED_CHECK_IN_NFT
                                                                        }
                                                                    >
                                                                        <NFTContainer>
                                                                            <StyledImage
                                                                                src={
                                                                                    nft.imageUrl ||
                                                                                    noimage
                                                                                }
                                                                                alt={`NFT image for ${nft.event.name}`}
                                                                            />
                                                                        </NFTContainer>
                                                                    </EventLink>
                                                                </div>
                                                                <>
                                                                    {renderNfts(
                                                                        [nft]
                                                                    )}
                                                                </>
                                                            </ReactCardFlip>
                                                            <EventLink
                                                                eventId={
                                                                    nft.event.id
                                                                }
                                                                ga4CustomEvent={
                                                                    GA4_CUSTOM_EVENT.PRESSED_CHECK_IN_NFT
                                                                }
                                                            >
                                                                <DateAndIconsContainer>
                                                                    <DateText>
                                                                        {DateFormatter.formatDate(
                                                                            nft
                                                                                .event
                                                                                .date
                                                                        )}
                                                                    </DateText>
                                                                    <IconContainer>
                                                                        {nft
                                                                            .event
                                                                            .canPostTrajectory && (
                                                                            <>
                                                                                <PictureIcon
                                                                                    color={
                                                                                        nft.hasPostedPicture
                                                                                            ? '#000000'
                                                                                            : '#AAAAAA'
                                                                                    }
                                                                                />
                                                                                <DiaryIcon
                                                                                    color={
                                                                                        nft.hasPostedDiary
                                                                                            ? '#000000'
                                                                                            : '#AAAAAA'
                                                                                    }
                                                                                />
                                                                            </>
                                                                        )}
                                                                    </IconContainer>
                                                                </DateAndIconsContainer>
                                                                <ContentText>
                                                                    <EventName
                                                                        name={
                                                                            nft
                                                                                .event
                                                                                .name
                                                                        }
                                                                    />
                                                                </ContentText>
                                                            </EventLink>
                                                        </NFTWrapper>
                                                    )
                                                )}
                                            </NFTAttendanceContentWrapper>
                                        </NFTAttendanceContent>
                                    </NFTAttendanceWrapper>
                                )}
                            </NFTAttendanceContainer>
                        </div>
                    );
                }}
            </Await>
        </React.Suspense>
    );
};

/**
 * `NftHistory`は、`DeferredNft`コンポーネントをレンダリングするReactの関数コンポーネントです。
 * NFTの履歴を表示するページを表現します。
 *
 * @return NFTの履歴ページ。
 */
const NftHistory: React.FC = () => {
    return (
        <>
            <DeferredNft />
        </>
    );
};

export default NftHistory;
