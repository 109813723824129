import { NftDetail } from '../../types/nftDetailType';

export const nftDetailValidator = (nft: NftDetail): boolean => {
    if (!nft) {
        return false;
    }
    if (!nft.event) {
        return false;
    }
    if (!nft.event.id) {
        return false;
    }
    if (!nft.event.name) {
        return false;
    }
    if (!nft.event.shortName) {
        return false;
    }
    if (!nft.event.date) {
        return false;
    }
    if (!nft.event.color) {
        return false;
    }
    if (typeof nft.event.canPostTrajectory !== 'boolean') {
        return false;
    }
    if (typeof nft.accountNftId !== 'number') {
        return false;
    }
    if (typeof nft.imageUrl !== 'string') {
        return false;
    }
    if (typeof nft.nftAddress !== 'string') {
        return false;
    }
    if (typeof nft.description !== 'string') {
        return false;
    }
    return true;
};
